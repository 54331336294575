
import { defineComponent, ref } from 'vue'
import { useModes } from '@/compositions/modes'
import ContactDetailsNotesNavigation from '@/components/pages/contacts/organizationDetails/ContactDetailsNotesNavigation.vue'
import ContactDetailsActivitiesList from '@/components/pages/contacts/organizationDetails/ContactDetailsActivitiesList.vue'
import { activitiesFilter } from '@/definitions/shared/filters/filtersList/data'
import { organizationActivities } from '@/mock/activities'
import { useFilters } from '@/compositions/filters'
import type { ActivityCardType } from '@/definitions/shared/templates/activity/types'

export default defineComponent({
  name: 'ContactDetailsActivities',
  components: {
    ContactDetailsNotesNavigation,
    ContactDetailsActivitiesList,
  },
  props: {
    activities: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()

    const { filters } = useFilters(activitiesFilter)
    const organizationActivitiesValue = ref<ActivityCardType[]>(organizationActivities.filter((item) => item.type === 'note'))
    const activitiesList = ref()
    const toggleExpand = () => activitiesList.value.toggleExpand()
    const search = ref('')

    return {
      organizationActivitiesValue,
      filters,
      isEmptyMode,
      activitiesList,
      search,
      organizationActivities,
      toggleExpand,
    }
  },
})
